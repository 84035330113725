// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/MinecraftTen.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Roboto.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Minecraft.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n\tfont-family: 'Minecraft Ten';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n\tfont-family: 'Roboto';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n\tfont-family: 'Minecraft';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n", "",{"version":3,"sources":["webpack://./src/assets/fonts/index.css"],"names":[],"mappings":"AAAA;CACC,4BAA4B;CAC5B,4CAAqC;AACtC;;AAEA;CACC,qBAAqB;CACrB,4CAA+B;AAChC;;AAEA;CACC,wBAAwB;CACxB,4CAAkC;AACnC","sourcesContent":["@font-face {\n\tfont-family: 'Minecraft Ten';\n\tsrc: url('../fonts/MinecraftTen.ttf');\n}\n\n@font-face {\n\tfont-family: 'Roboto';\n\tsrc: url('../fonts/Roboto.ttf');\n}\n\n@font-face {\n\tfont-family: 'Minecraft';\n\tsrc: url('../fonts/Minecraft.otf');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
